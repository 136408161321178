'use strict';

import config from './config.js';

import EditorJS from '@editorjs/editorjs';
import ListTool from './list-tool';

// Local storage keys
const LOCAL_STORAGE_KEY_TASKS = 'tasks';

// Variables used when restoring notes with CTRL+Z/CTRL+Y
let previousRevision = null; // string
let nextRevision = null; // object

// Global variables - to be refactored
window.tagRegex = /(#[^<\s]*)/g;
window.completedTaskRegex = /\-{5,}/;
window.tabsRegex = /^\t+/;
window.activeTagsList = null;
window.ballGifRegex = /<img class="ball-gif".+>/g;

window.completedTaskTag = '#completed';
window.completedTaskDetectionDelay = 300; // 300ms delay when listening for '-' input to trigger completed task detection
window.completedTaskDetectionTimeout = 0;

window.defineBlockPrefix = '>define';
window.defineBlockPrefixEncoded = getEncodedString(window.defineBlockPrefix);

window.addTagStyle = (tagName, tagStyle) => {
  tagColorStyleSheet.insertRule(`.tag-${tagName.toLowerCase()}{ ${tagStyle} }`, tagColorStyleSheet.cssRules.length);
};

window.currentlyRenderedBlockId = -1;
window.renderedBlocksText = []; // It is needed to keep rendered blocks here, because the blocks are not in the DOM until they are all processed,
// thus it's impossible to get tags from the DOM during the initial rendering
window.renderingInProgress = false; // true if rendering tasks from localStorage - on startup, or when rendering previous version after using CTRL+Z

// Load colors
const tagColorStyleSheet = document.getElementById('tag-colors-style').sheet;

let focusDataBackup = null; // Used when toggling focus with Ctrl+/

const editor = new EditorJS({
  tools: {
    customList: {
      class: ListTool,
      inlineToolbar: false
    }
  },
  defaultBlock: 'customList',
  placeholder: 'Start typing...',
  onReady: () => {
    if (location.hostname === 'localhost') {
      console.log('api', editor);
    }
    handleClickOnEmptySpace(editor);
    loadTasks();
    localStorage.removeItem('done_tasks'); // Remove redundant data in 'done_task' key - it was used previously
    localStorage.removeItem('colors');
    localStorage.removeItem('tag_config');
  },
  onChange: () => {
    nextRevision = null;
    // Get the total number of blocks in the editor
    const blockCount = editor.blocks.getBlocksCount();

    // Loop through each block and:
    // - remove empty blocks
    // - apply style definitions
    const blocksToRemove = [];
    // Remove previous CSS rules
    while (tagColorStyleSheet.cssRules.length > 0) {
      tagColorStyleSheet.deleteRule(0);
    }

    const tagToCommentMap = {};
    const tagsArray = [];
    const blockData = []; // define, comment

    for (let i = 0; i < blockCount; i++) {
      const block = editor.blocks.getBlockByIndex(i);
      if (block.isEmpty) {
        blocksToRemove.push(i);
      } else {
        // Get block from the DOM
        const blockElem = getBlockElementById(block.id);
        // Is it a define block?
        const blockInnerText = blockElem.innerText;
        const blockInnerTextTrimmed = blockInnerText.trim();
        const isDefine = blockInnerTextTrimmed.startsWith(defineBlockPrefix);
        if (isDefine) {
          // Handle tag style definition
          const tags = blockInnerText.match(window.tagRegex);
          blockData.push({ type: 'define', tags });
          const styleDef = blockInnerText
            .split(' ')
            .filter((e) => !e.startsWith('>') && !e.startsWith('#'))
            .join(' ');
          if (styleDef) {
            tags.forEach((tag) => {
              const tagStrippedHash = tag.replaceAll('#', '');
              addTagStyle(tagStrippedHash, styleDef); // Apply style visually
            });
          } else {
            console.warn(`Cannot find style definition in ${blockInnerText}`);
          }
        } else if (blockInnerTextTrimmed.startsWith(';')) {
          // It's a comment declaration
          // Find parent definition
          for (let j = i - 1; j >= 0; j--) {
            if (!blockData[j]?.type) {
              break;
            }
            if (blockData[j].type === 'define') {
              // Get tags from the definition
              blockData[j].tags.forEach((tag) => {
                const lowercaseTag = tag.toLowerCase();
                tagToCommentMap[lowercaseTag] = tagToCommentMap[lowercaseTag] || [];
                tagToCommentMap[lowercaseTag].push(blockInnerText);
              });
              break;
            }
          }

          blockData.push({ type: 'comment' });
        } else {
          tagsArray.push({ idx: i, tags: blockInnerText.match(window.tagRegex)?.map((t) => t.toLowerCase()) });
          blockData.push({ type: null });
        }
      }
    }

    if (tagsArray.length > 0) {
      // Insert defined comments under every tag
      let i = 1;
      for (const tagEntry of tagsArray) {
        if (Array.isArray(tagEntry.tags)) {
          for (const tag of tagEntry.tags) {
            if (Array.isArray(tagToCommentMap[tag])) {
              for (const tagToComment of tagToCommentMap[tag]) {
                // Is the comment already inserted?
                // Get task range and find the comment in the range
                const taskRange = getTaskRange(tagEntry.idx + i - 1, editor, true);
                let insertComment = true;
                for (let i = taskRange.startIdx + 1; i <= taskRange.endIdx; i++) {
                  const blockContent = getBlockElementById(editor.blocks.getBlockByIndex(i).id).innerText;
                  if (blockContent.includes(tagToComment)) {
                    insertComment = false;
                    break;
                  }
                }
                if (!insertComment) {
                  continue;
                }
                let indentationString = '';
                const tabsCount = getBlockElementById(
                  editor.blocks.getBlockByIndex(tagEntry.idx + i - 1).id
                ).innerText.match(window.tabsRegex)?.[0]?.length;
                for (let i = 0; i < tabsCount; i++) {
                  indentationString += '\t';
                }
                editor.blocks.insert(
                  'customList',
                  {
                    text: indentationString + tagToComment
                  },
                  {},
                  tagEntry.idx + i
                );
                i++;
              }
            }
          }
        }
      }
    }

    if (blocksToRemove.length > 0) {
      const currentBlockIdx = editor.blocks.getCurrentBlockIndex();
      blocksToRemove.reverse();
      // Remove all empty blocks except of the current one
      if (blocksToRemove.includes(currentBlockIdx)) {
        blocksToRemove.splice(blocksToRemove.indexOf(currentBlockIdx), 1);
      }
      blocksToRemove.forEach((idx) => editor.blocks.delete(idx));
    }

    editor
      .save()
      .then((output) => {
        const stringifiedTasks = JSON.stringify(output);
        previousRevision = localStorage.getItem(LOCAL_STORAGE_KEY_TASKS);
        // console.info('Saving tasks:', stringifiedTasks);
        if (stringifiedTasks) {
          // Avoid saving undefined tasks - it occurs when we mess with editor.js API too much
          localStorage.setItem(LOCAL_STORAGE_KEY_TASKS, stringifiedTasks);
        }
        hideSavingIndicator();
      })
      .catch((err) => {
        console.error(err);
      });
  }
});

function loadTasks() {
  const tasks = localStorage.getItem(LOCAL_STORAGE_KEY_TASKS);
  if (tasks) {
    try {
      const parsedTasks = JSON.parse(tasks);
      // Remove ball gif - it is needed to avoid showing the ball gif again, if
      // user closed the page before the 5000ms timeout kicked in
      parsedTasks.blocks = parsedTasks.blocks.map((block) => {
        block.data.text = block.data.text.replace(ballGifRegex, '');
        return block;
      });

      window.renderingInProgress = true;
      editor.render(parsedTasks).then(() => {
        resetBlocksRenderState();
      });
      // setTimeout is used, because otherwise, the focus won't work
      // seems like an issue with editor.js: https://github.com/codex-team/editor.js/issues/2282
      window.setTimeout(() => {
        editor.focus();
        editor.configuration.onChange(); // Triger >define detection
      }, 0);
    } catch (e) {
      console.error(e);
    }
  }
}

window.addEventListener('keydown', async (evt) => {
  if (evt.code === 'Escape') {
    hideTagList();
  } else if (evt.ctrlKey && (evt.code === 'KeyZ' || evt.code === 'KeyY') && document.activeElement?.parentElement) {
    // Handle CTRL+Z/CTRL+Y
    const cursorPos = getCursorPos();
    let activeBlock = document.activeElement.parentElement;
    while (!activeBlock.dataset.id) {
      activeBlock = activeBlock.parentElement;
      if (!activeBlock) {
        return;
      }
    }
    let revisionToRender = null;
    if (evt.code === 'KeyZ') {
      // Save activeElement
      // Restore previous revision
      nextRevision = await editor.save();
      revisionToRender = JSON.parse(previousRevision);
    } else if (evt.code === 'KeyY') {
      // Restore next revision
      revisionToRender = nextRevision;
    }
    if (revisionToRender) {
      resetBlocksRenderState();
      window.renderingInProgress = true;
      editor.render(revisionToRender).then(() => {
        if (activeBlock) {
          const blockToFocus = getBlockElementById(activeBlock.dataset.id)?.querySelector('.list-element');
          if (blockToFocus) {
            blockToFocus.focus();
            setCursorPos(cursorPos);
          } else {
            // Are we removing everything?
            editor.focus();
          }
        }
        resetBlocksRenderState();
      });
    }
  } else if (evt.ctrlKey && evt.key === '/') {
    // Toggle focus between the task list and filtering field
    if (!document.activeElement) {
      return;
    }
    if (document.activeElement.tagName === 'INPUT') {
      if (focusDataBackup) {
        const caretSet = editor.caret.setToBlock(focusDataBackup.blockIdx, 'start');
        if (!caretSet) {
          onCaretError(focusDataBackup.blockIdx, 'start');
        }
        // We need custom function to set the cursor position, with a delay, because
        // editor.js uses a 20ms timeout under the hood in `setToBlock` method
        window.setTimeout(() => {
          window.setCursorPos(focusDataBackup.cursorPos);
        }, 20);
      } else {
        const caretSet = editor.caret.setToBlock(0, 'start');
        if (!caretSet) {
          onCaretError(0, 'start');
        }
      }
    } else {
      focusDataBackup = {
        blockIdx: editor.blocks.getCurrentBlockIndex(),
        cursorPos: getCursorPos()
      };
      document.getElementById('filter-input').focus();
    }
  }
});

window.addEventListener('click', () => {
  if (window.activeTagsList) {
    // Clicked outside of the tag clist - otherwise, the click would be handled in list-tool.js
    hideTagList();
  }
});

window.getCursorPos = () => {
  const selection = window.getSelection();

  let cursorPos = 0;
  let previousSibling = getPreviousSibling(selection.anchorNode);
  while (previousSibling) {
    cursorPos += getNodeLength(previousSibling);
    previousSibling = getPreviousSibling(previousSibling);
  }

  // selection.rangeCount is 0, when text is selected using Shift and Arrow Up / Down
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0).cloneRange();
    return cursorPos + range.startOffset;
  }
};

function getPreviousSibling(node) {
  if (!node) {
    return null;
  }
  // This function is used to traverse across the DOM in a contenteditable div
  let previousSibling = node.previousSibling;
  if (!previousSibling) {
    const parent = node.parentNode;
    if (parent.tagName !== 'DIV') {
      previousSibling = parent.previousSibling;
    }
  }
  return previousSibling;
}

window.getNodeForCursorPos = (pos) => {
  const activeEl = document.activeElement;

  // Set start position of range
  let nodeToFocus = activeEl.childNodes[0].childNodes[0];
  let focusPos = pos;
  if (getNodeLength(nodeToFocus) < pos) {
    let nodesLength = 0;
    while (true) {
      const nodeLength = getNodeLength(nodeToFocus);
      nodesLength += nodeLength;
      if (focusPos <= getNodeLength(nodeToFocus) || !nodeToFocus) {
        break;
      } else {
        nodeToFocus = nodeToFocus.nextSibling;
        focusPos -= nodeLength;
      }
    }
  }

  if (focusPos < 0) {
    focusPos = 0;
  }
  // nodeToFocus could be null, when the edited block is empty
  if (nodeToFocus && nodeToFocus.nodeType === 1) {
    // Focus on text node - cannot focus on a `span` directly
    nodeToFocus = nodeToFocus.childNodes[0];
  }
  if (!nodeToFocus) {
    // Select last child node - used, when CTRL+Z is pressed
    nodeToFocus = activeEl.childNodes[activeEl.childNodes.length - 1];
    focusPos = getNodeLength(nodeToFocus);
  }

  return { nodeToFocus, focusPos };
};

window.setCursorPos = (pos) => {
  if (pos === null || pos === undefined) {
    console.warn(`Cannot set cursor position to ${pos}`);
    return;
  }
  if (location.hostname === 'localhost') {
    console.warn(`Setting cursor pos to ${pos}`);
  }
  const nodeToFocusAndFocusPos = getNodeForCursorPos(pos);
  if (!nodeToFocusAndFocusPos.nodeToFocus) {
    return;
  }

  // Creates range object
  const setpos = document.createRange();

  // Creates object for selection
  const set = window.getSelection();

  setpos.setStart(nodeToFocusAndFocusPos.nodeToFocus, nodeToFocusAndFocusPos.focusPos);

  // Collapse range within its boundary points
  // Returns boolean
  setpos.collapse(true);

  // Remove all ranges set
  set.removeAllRanges();

  // Add range with respect to range object.
  set.addRange(setpos);
};

window.setCursorPosToNodeEnd = (node) => {
  // Creates range object
  const setpos = document.createRange();

  // Creates object for selection
  const set = window.getSelection();

  setpos.setStart(node, getNodeLength(node));

  // Collapse range within its boundary points
  // Returns boolean
  setpos.collapse(true);

  // Remove all ranges set
  set.removeAllRanges();

  // Add range with respect to range object.
  set.addRange(setpos);
};

window.getNodeLength = (node) => {
  // return length of span or text node
  if (node) {
    if (node.nodeType === 3) {
      return node.length;
    } else if (node.childNodes[0]) {
      return Array.from(node.childNodes)
        .map((cn) => getNodeLength(cn))
        .reduce((a, b) => a + b, 0);
    }
  }
  return 0;
};

function getSavingIndicator() {
  return document.getElementById('saving-indicator');
}

window.showSavingIndicator = () => {
  const savingIndicator = getSavingIndicator();
  if (savingIndicator) {
    savingIndicator.removeAttribute('hidden');
  }
};

window.hideTagList = (blurActiveElement = false) => {
  if (window.activeTagsList) {
    window.activeTagsList.remove();
    window.activeTagsList = null;
  } else if (blurActiveElement) {
    document.activeElement?.blur();
  }
};

function hideSavingIndicator() {
  const savingIndicator = getSavingIndicator();
  if (savingIndicator) {
    savingIndicator.setAttribute('hidden', 'hidden');
  }
}

window.getBlockElementById = (blockId) => {
  // Gets the element from the DOM
  return document.querySelector(`[data-id="${blockId}"]`);
};

window.getBallGifCode = () => {
  return `<img class="ball-gif" src="${config.ballGifFilename}?t=${performance.now()}">`;
};

function getEncodedString(input) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = input;
  return textarea.innerHTML;
}

function resetBlocksRenderState() {
  window.currentlyRenderedBlockId = -1;
  window.renderedBlocksText = [];
  window.renderingInProgress = false;
}

window.getTaskRange = (searchStartIdx, api, onlySearchForward = false) => {
  const blockCount = api.blocks.getBlocksCount();
  if (searchStartIdx > blockCount - 1) {
    return null;
  }
  // Given a specific block index, return the range of the task
  // Task can contain multiple subtasks and comments
  let startIdx = searchStartIdx;
  let endIdx = api.blocks.getBlocksCount() - 1;
  // Loop variables
  let i, blockElem;

  if (searchStartIdx > 0 && !onlySearchForward) {
    blockElem = window.getBlockElementById(api.blocks.getBlockByIndex(searchStartIdx).id);

    if (isBlockCommentOrSubtask(blockElem.innerText)) {
      // Did we start in the middle of a task?
      // Find start
      i = searchStartIdx - 1;
      while (i >= 0) {
        blockElem = window.getBlockElementById(api.blocks.getBlockByIndex(i).id);
        if (!isBlockCommentOrSubtask(blockElem.innerText)) {
          startIdx = i;
          break;
        }
        i--;
      }
    }
  }

  if (searchStartIdx < endIdx) {
    // Find end
    i = searchStartIdx + 1;
    while (i <= endIdx) {
      blockElem = window.getBlockElementById(api.blocks.getBlockByIndex(i).id);
      if (!isBlockCommentOrSubtask(blockElem.innerText)) {
        endIdx = i - 1;
        break;
      }
      i++;
    }
  }

  console.warn({ startIdx, endIdx });

  return {
    startIdx,
    endIdx
  };
};

window.isBlockCommentOrSubtask = (blockText) => {
  if (!blockText) {
    return false;
  }
  return blockText.startsWith('\t') || blockText.startsWith(';');
};

window.addEventListener('load', () => {
  const filterInput = document.getElementById('filter-input');
  const onInput = () => {
    const filterValue = filterInput.value.trim();
    const editorBlocks = document.querySelectorAll('.ce-block');
    if (filterValue) {
      editorBlocks.forEach((block) => {
        if (block.innerText.includes(filterValue)) {
          block.removeAttribute('hidden');
        } else {
          block.setAttribute('hidden', 'hidden');
        }
      });
    } else {
      // Show all
      editorBlocks.forEach((block) => block.removeAttribute('hidden'));
    }
  };
  filterInput.addEventListener('input', onInput);
  filterInput.addEventListener('keydown', (evt) => {
    if (evt.key === 'Enter') {
      const filterValue = filterInput.value.trim();
      // Clear search input
      filterInput.value = '';
      onInput();
      // Focus first result, if found
      const editorBlocks = document.querySelectorAll('.ce-block');
      if (filterValue) {
        for (const [blockIdx, block] of editorBlocks.entries()) {
          if (block.innerText.includes(filterValue)) {
            const caretSet = editor.caret.setToBlock(blockIdx, 'start');
            if (!caretSet) {
              onCaretError(blockIdx, 'start');
            }
            return;
          }
        }
      }
    }
  });
});

function handleClickOnEmptySpace(api) {
  window.addEventListener('click', (evt) => {
    // Do we have a focused block?
    const activeEl = document.activeElement;
    if (!activeEl || activeEl.tagName === 'BODY') {
      // Are we below the last task?
      // Get last task
      const lastTask = document.querySelector('.ce-block:last-child');
      const rect = lastTask.getBoundingClientRect();
      const belowLastTask = rect.bottom < evt.y;
      if (!belowLastTask) {
        return;
      }
      // Insert new line above completed tasks and focus it
      const editorBlocks = document.querySelectorAll('.ce-block');
      let idxToInsertLine = null;
      for (const [idx, block] of editorBlocks.entries()) {
        if (block.innerText.includes(window.completedTaskTag)) {
          idxToInsertLine = idx;
          break;
        }
      }

      idxToInsertLine = idxToInsertLine || editor.blocks.getBlocksCount();

      api.blocks.insert(
        'customList',
        {
          text: ''
        },
        {},
        idxToInsertLine,
        true
      );
      // Focus new block manually - editor.js should do this for us, but it's broken
      const caretSet = api.caret.setToBlock(idxToInsertLine, 'start');
      if (!caretSet) {
        onCaretError(idxToInsertLine, 'start');
      }
    }
  });
}

window.onCaretError = (block, pos) => {
  console.error(`Cannot set caret to block ${block} at ${pos}`);
};
